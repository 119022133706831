.queryBuilder .chakra-input,
.queryBuilder .chakra-select__wrapper,
.queryBuilder .chakra-radio-group {
  background-color: white;
  border-color:#3182ce
}

.queryBuilder .chakra-select__wrapper {
  width: fit-content;
  display: inline-block;
}

.queryBuilder .chakra-input {
  width: auto;
  display: inline-block;
}

.queryBuilder .chakra-radio-group {
  display: inline-block;
}

.queryBuilder .rule-remove {
  background-color: #E53E3E;
}

.queryBuilder .ruleGroup-addGroup {
  visibility: hidden;
}


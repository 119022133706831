@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Remove daisy ui outline on form elements */
  .input:focus,
  .select:focus,
  .textarea:focus,
  .file-input:focus {
    @apply outline-0 !important;
  }

  /* Reset daisy ui default */
  .select {
    @apply font-normal !important;
  }

  /* Number Input Reset */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}
#root {
  height: 100vh;
  position: relative;
}
.clinic-calendar-container {
  height: calc(100vh - 10rem);
}
.patients-table-container {
  height: calc(100vh - 8rem);
}

@keyframes showAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}
.show {
  animation: showAnim 300ms;
}

@keyframes open-height {
  0% {
    height: 0;
  }
  100% {
    height: 5rem;
  }
}

.anim_height {
  animation: open-height 300ms forwards;
}

.show {
  animation: showAnim 300ms;
}

@keyframes showModalAnim {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.showModal {
  animation: showModalAnim 0.5s;
}

.blueGradient {
  background: linear-gradient(-180deg, #0500ff 5%, #5652ff 30%, #ffffff);
}
.blueGradient2 {
  background: linear-gradient(
    180deg,
    #0500ff 3.65%,
    rgba(109, 106, 255, 0.954097) 48.96%,
    rgba(102, 99, 255, 0.652172) 76.56%,
    rgba(87, 84, 255, 0) 99.98%,
    rgba(86, 82, 255, 0.0208333) 99.99%
  );
}
.blueGradient3 {
  background: linear-gradient(
    90deg,
    #9d9bff 43.39%,
    rgba(195, 193, 255, 0) 100%
  );
}

/* width */
.sbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.sbar::-webkit-scrollbar-track {
  filter: drop-shadow(0 4px 4px rgb(0, 0, 0, 0.25));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
}

/* Handle */
.sbar::-webkit-scrollbar-thumb {
  background: #817eff;
  border-radius: 17px;
}

.sbar2::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.sbar2::-webkit-scrollbar-track {
  border-radius: 17px;
  background-color: transparent;
}

/* Handle */
.sbar2::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 17px;
  filter: drop-shadow(0 4px 4px rgb(0, 0, 0, 0.25));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sbar2-purple::-webkit-scrollbar-thumb {
  background: #817eff;
}

.sbar2-purple::-webkit-scrollbar-track {
  background: transparent;
}

.board::-webkit-scrollbar {
  width: calc(0.3rem);
  height: calc(0.3rem);
}

.board::-webkit-scrollbar-track {
  background: transparent;
}

.board::-webkit-scrollbar-thumb {
  background: #5754ff;
  border-radius: 100px;
}

.sbar3::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.sbar3::-webkit-scrollbar-track {
  background: transparent;
}

.sbar3::-webkit-scrollbar-thumb {
  background: #5754ff;
  border-radius: 100px;
}

.shadow01 {
  filter: drop-shadow(0 4px 4px rgb(0, 0, 0, 0.25));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px -4px 0px rgba(0, 0, 0, 0.25);
}
.shadow02 {
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.25);
}
.shadow-ntf {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.bg {
  background-color: #5754ff;
  height: 50px;
  width: 50px;
}

.form-field {
  border: none;
}

.slide {
  transition: 0.5s;
}
.chatSadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.selectedChat {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 1);
}

@keyframes writing {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}

.writingAnim > span {
  animation: writing 1s calc(var(--d)) infinite;
  flex-shrink: 0;
}

.bkGradient {
  background: linear-gradient(
    180deg,
    #fffefe 0%,
    rgba(109, 106, 255, 0.4) 50%,
    rgba(109, 106, 255, 0.4) 60%,
    rgba(255, 255, 255, 0.29) 100%
  );
}

.bkGradient2 {
  background: linear-gradient(180deg, #293fff 0%, rgba(209, 2, 2, 0) 100%);
}

.bkGradient3 {
  background: linear-gradient(
    90deg,
    #5754ff 0%,
    #000002 99.99%,
    rgba(108, 117, 255, 0.11) 100%
  );
}

.bkHeadGradient {
  background: linear-gradient(90deg, #ffff, rgb(143, 140, 255, 0.5) 50%, #ffff);
}

.clip_selection {
  clip-path: polygon(0 60%, 100% 60%, 100% 0, 0 0);
}

.card-element * {
  width: 100%;
  border: "1px solid  #E9E9E9";
  color: #a7a7a7;
}

#card-element *::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

#card-element *::-webkit-scrollbar-track {
  background: transparent;
}

#card-element *::-webkit-scrollbar-thumb {
  background: #5754ff;
  border-radius: 100px;
}

.card-element *:focus {
  border: "1px solid  #B2B0FE";
  outline: none;
  box-shadow: none important!;
}

.show-icon {
  animation: oscillate-end 1s ease-in-out forwards;
}

@keyframes oscillate-end {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.swal2-container button.swal2-deny {
  background-color: #dc3741 !important;
}
.swal2-container button.swal2-confirm {
  background-color: #7066e0 !important;
}
.swal2-container button.swal2-cancel {
  background-color: #6e7881 !important;
}
